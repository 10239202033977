<!-- SvgIcon.vue -->
<template>
  <component :is="dynamicComponent"></component>
</template>

<script>
export default {
  props: ["name"],
  computed: {
    dynamicComponent() {
      return () => import(`@/assets/svg/${this.name}.svg`);
    },
  },
};
</script>
